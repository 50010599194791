type ApiErrorData = {
  errors?: unknown;
  status: number;
};

class ApiError {
  errors?: unknown;
  status: number;
  constructor({ errors, status }: ApiErrorData) {
    this.errors = errors || [];
    this.status = status;
  }

  get message(): string {
    return `Error Code: ${this.status}, Errors: ${
      this.errors ? JSON.stringify(this.errors) : 'Empty'
    }`;
  }
}

class BadRequestError extends ApiError {}
class NotFoundError extends ApiError {}
class UnknownError extends ApiError {}

export { ApiError, BadRequestError, NotFoundError, UnknownError };

import Typography from '@mui/material/Typography';
import { Form, Formik, type FormikHelpers } from 'formik';
import * as React from 'react';
import { DynamicForm } from '~/components/dynamic-form';
import { ProfileFormInputs } from '~/components/form-element';
import { Progress } from '~/components/progress';
import { InviteErrorSection } from '~/components/sections/invite-error-section';
import { MessageSection } from '~/components/sections/message-section';
import { ProfileFormSection } from '~/components/sections/profile-form-section';
import { SignupSection } from '~/components/sections/signup-section';
import { SwitchAccountsSection } from '~/components/sections/switch-account-section';
import { defaultRequiredFields } from '~/constants/shared';
import { useLoginSilently } from '~/hook/use-login-silently';
import { useCompleteSignup, useEnrichSignupInfo, useGetSignupInfo } from '~/hook/use-signup';
import { useUserSession } from '~/hook/use-user-session';
import type { DetailedProfileInfo, FormValues, RequiredProfileFields } from '~/types';
import { mergeArrayable } from '~/utils/array';
import { compact, keys, omit, pick } from '~/utils/object';
import {
  enrichProfileValues,
  getRequiredFieldsByKeys,
  isErrorWithFormValue,
  mapDetailedProfileInfo,
} from '~/utils/profile';

import { SignupComplete } from './signup.complete';

function SignupPage() {
  const {
    isError,
    isGettingSignupInfo,
    hasEmptyFields,
    profileInfo,
    requiredProfileFields,
    errorCode,
    signupToken,
    assetCallbackUrl,
  } = useGetSignupInfo();
  const { enrichInfo, isEnriching } = useEnrichSignupInfo({
    signupToken,
    options: {
      enabled: hasEmptyFields,
    },
  });
  const { user, isAuthenticated, isCheckingUserSession, loggedWithDiffUser, loginWithRedirect } =
    useUserSession(profileInfo);
  const completeSignupMutation = useCompleteSignup({
    profileInfo,
  });
  const loginSilently = useLoginSilently();

  const [forceDisplayFields, setForceDisplayFields] = React.useState<RequiredProfileFields>([]);

  const pageIsReady = !isCheckingUserSession && !isGettingSignupInfo && !isEnriching;

  if (!pageIsReady) return <MessageSection title={<Progress />} />;

  if (isError || !profileInfo) return <InviteErrorSection errorCode={errorCode} />;

  if (loggedWithDiffUser) {
    return <SwitchAccountsSection invitedEmail={profileInfo.email} />;
  }

  if (!hasEmptyFields) {
    return <SignupComplete assetCallbackUrl={assetCallbackUrl} signupToken={signupToken} />;
  }

  if (profileInfo.isSso && !isAuthenticated) {
    const stateBeforeLogin = {
      returnTo: `/signup?signupToken=${signupToken}`,
    };

    loginWithRedirect({
      login_hint: profileInfo.email,
      appState: stateBeforeLogin,
      connection: profileInfo.ssoConnectionName,
    });

    return <MessageSection title={<Progress />} />;
  }

  const signupProfileInfo: DetailedProfileInfo = {
    ...profileInfo,
    firstName: user?.given_name || profileInfo.firstName,
    lastName: user?.family_name || profileInfo.lastName,
  };
  const initialValues = mapDetailedProfileInfo(signupProfileInfo);

  const handleCompleteSignup = (
    formValues: FormValues,
    { setErrors, setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    const completeSignupDto = omit(
      {
        ...formValues,
        isEmailVerified: true,
        signupToken,
      },
      ['hasExistingPassword', 'isSso'],
    );

    completeSignupMutation.mutate(completeSignupDto, {
      onSuccess: (_, payload) => {
        if (assetCallbackUrl) {
          if (payload.password) {
            loginSilently({
              email: payload.email,
              password: payload.password,
              assetCallbackUrl,
            });
          } else {
            window.location.href = assetCallbackUrl;
          }
        }
      },
      onError: (ex) => {
        setSubmitting(false);

        if (isErrorWithFormValue(ex)) {
          setErrors(ex.errors);

          const displayFields = getRequiredFieldsByKeys(keys(ex.errors));
          setForceDisplayFields(displayFields);
        }
      },
    });
  };

  return (
    <DynamicForm
      initialValues={initialValues}
      fields={() => {
        const requiredFields = !requiredProfileFields.length
          ? defaultRequiredFields
          : requiredProfileFields;

        return mergeArrayable(requiredFields, 'Email', 'Sso', 'Terms');
      }}
      enrich={(initialValues, requiredKeys) => {
        const enrichValues = compact(enrichInfo);
        const formValues = pick(
          enrichProfileValues(initialValues, enrichValues as unknown as FormValues),
          requiredKeys,
        );

        return formValues;
      }}
      forceDisplayFields={forceDisplayFields}
    >
      {({ validationSchema: signupValidationSchema, values }) => (
        <Formik
          initialValues={values}
          onSubmit={handleCompleteSignup}
          validationSchema={signupValidationSchema}
        >
          {({ isValid, isSubmitting, errors }) => {
            return (
              <SignupSection>
                <Form>
                  <ProfileFormSection
                    title="Complete your profile"
                    description={
                      <>
                        <Typography variant="body2">
                          Are your details correct? Check your profile details to start
                        </Typography>
                        <Typography
                          data-ansarada-ccd
                          variant="body2"
                          data-test-id="signed-up-email-description"
                        >
                          {`You're signed up  with ${values.email}`}
                        </Typography>
                      </>
                    }
                    submitButtonText="Complete profile"
                    isSubmitting={isSubmitting}
                    isDisableSubmit={!isValid}
                    submitErrorMsg={errors.signupToken}
                  >
                    <ProfileFormInputs
                      profileValues={values}
                      hideExistingValues
                      hideTermsAndConditions={values.termsAndConditionsAccepted}
                    />
                  </ProfileFormSection>
                </Form>
              </SignupSection>
            );
          }}
        </Formik>
      )}
    </DynamicForm>
  );
}

export default SignupPage;

import { useMediaQuery, useTheme } from '@mui/material';

type BreakpointConfig = {
  query: 'down' | 'up' | 'only' | 'not';
  screenSize: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

export const useBreakpoints = (...args: BreakpointConfig[]) => {
  const theme = useTheme();

  return args.map(({ query, screenSize }) => {
    const breakpoint = theme.breakpoints[query](screenSize);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMediaQuery(breakpoint);
  });
};

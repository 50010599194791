import { Icon, SvgProps } from './icon';

export const LogomarkDrova = (props: SvgProps) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 280 280">
      <path
        strokeWidth="0px"
        className="cls-2"
        fill="#001c27"
        d="m210.66,140c0-39.31-31.87-71.18-71.18-71.18s-71.18,31.87-71.18,71.18H4.85C4.85,65.65,65.12,5.37,139.47,5.37s134.63,60.27,134.63,134.63h-63.44Z"
      />
      <path
        strokeWidth="0px"
        className="cls-1"
        fill="#FF4F01"
        d="m68.29,140c0,39.31,31.87,71.18,71.18,71.18,39.31,0,71.18-31.87,71.18-71.18h63.44c0,74.35-60.27,134.63-134.63,134.63S4.85,214.35,4.85,140h63.44Z"
      />
    </svg>
  </Icon>
);

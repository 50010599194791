export const primary = {
  500: '#FF4F01',
  600: '#E63300',
  100: '#FFBFB3',
};

export const secondary = {
  400: '#d6dcdc',
};

export const warning = {
  main: '#FB6900',
  dark: '#E86101',
  light: '#FC7F25',
};

export const common = {
  white: '#fff',
  black: '#001C27',
};

import { useEventCallback } from '@mui/material';
import { isNil, isUndefined } from 'lodash';
import { useEffect, useState } from 'react';
import { useFirstMountState } from 'react-use';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useInputValue<T, OnChange extends (...params: any[]) => any>(ops: {
  value?: T;
  defaultValue?: T;
  onChange?: OnChange;
}) {
  const { defaultValue, onChange, value: superValue } = ops;
  const [internalValue, setInternal] = useState(superValue ?? defaultValue);

  const firstRender = useFirstMountState();
  useEffect(() => {
    if (firstRender) return;
    if (superValue === internalValue || isUndefined(superValue)) return;

    setInternal(superValue);
  }, [superValue]);

  const handleInternalValueChange = useEventCallback((...params) => {
    onChange?.(...params);

    const [eventObjectOrValue, maybeValue] = params;
    let newValue: T;

    const isFirstParamsEventObject =
      eventObjectOrValue &&
      typeof eventObjectOrValue === 'object' &&
      'target' in eventObjectOrValue;

    if (isFirstParamsEventObject || (isNil(eventObjectOrValue) && !isNil(maybeValue))) {
      newValue = maybeValue;
    } else {
      newValue = eventObjectOrValue as T;
    }

    if (isUndefined(newValue)) {
      console.warn('Component is changed from controlled to uncontrolled');
    } else {
      setInternal(newValue);
    }
  }) as OnChange;

  return {
    internalValue,
    handleInternalValueChange,
  };
}

import type { RequiredProfileFields } from '~/types';

export const defaultRequiredFields: RequiredProfileFields = [
  'FirstName',
  'LastName',
  'CompanyName',
  'Location',
  'Password',
  'JobTitle',
  'PhoneNumber',
];

export const MaxLengthField = {
  FirstName: 24,
  LastName: 24,
  Email: 100,
  PhoneNumber: 50,
  Company: 255,
} as const;

import { MenuItem } from '@mui/material';
import { Children, ReactElement, ReactNode, cloneElement, isValidElement } from 'react';

const getClass = (element: string, modifier?: string) => {
  return `TextField__${element}${modifier ? `--${modifier}` : ''}`;
};

export const classes = {
  root: getClass('root'),
  label: getClass('label'),
  placeholder: getClass('placeholder'),

  error: getClass('root', 'error'),
  disabled: getClass('root', 'disabled'),
  loading: getClass('root', 'loading'),

  selectMenu: getClass('selectMenu'),
  selectIcon: getClass('selectIcon'),
};

type Option = {
  label: string;
  value: string;
};

/**
 * [1] append data-ansarada-ccd for each menu items
 * [2] append None option in case `noneOption` exists
 */
export function applyCcdToChildrenAndAddNoneOption(
  children: ReactNode,
  dataAnsaradaCcd?: boolean,
  noneOption?: boolean,
): ReactElement[] {
  const securedChildren =
    Children.map(children, (child) =>
      cloneElement(child as ReactElement, {
        'data-ansarada-ccd': dataAnsaradaCcd,
      }),
    ) ?? [];

  if (noneOption) {
    securedChildren.unshift(<MenuItem value="">None</MenuItem>);
  }

  return securedChildren;
}

export function getSelectChildrenLabels(childNodes: ReactNode): Option[] {
  return (
    Children.map(childNodes, (child) => {
      if (!isValidElement(child)) return null;
      return { value: child.props.value as string, label: child.props.children as string };
    })?.filter(Boolean) ?? []
  );
}

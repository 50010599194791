import { type UseMutationOptions, useMutation } from '@tanstack/react-query';
import { useClient } from '~/context/auth-context';
import type { RequestResetMFADto } from '~/types';

export const useRequestResetMFA = <T extends void = void>(
  options?: UseMutationOptions<T, unknown, RequestResetMFADto>,
) => {
  const client = useClient<T>();

  return useMutation(
    (email) =>
      client(`signup/v1/emails/reset-mfa/request`, {
        method: 'POST',
        data: {
          email,
        },
      }),
    options,
  );
};

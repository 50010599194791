import { ThemeProvider } from '@mui/material';
import { ComponentProps, ReactNode, forwardRef, useImperativeHandle } from 'react';
import { Root, createRoot } from 'react-dom/client';

import { DrovaLight } from '@ansarada/mui-themes/lib/drova';

import { Toast, ToastVariant } from '.';
import { ToastAPI, useInternalToast } from './internal/useInternalToast';

type ToastProps = ComponentProps<typeof Toast>;
type ToastManager = object & ToastAPI;

type ToastControl = {
  root: Root;
  manager?: ToastManager;
};

type ToastConfig =
  | {
      type: `${ToastVariant}`;
      props: Omit<ToastProps, 'variant'>;
    }
  | {
      type: 'destroy';
      key: string;
    };

let control: ToastControl;
let toastQueue: ToastConfig[] = [];

type ToastRendererRef = {
  manager: ToastManager;
};

const GlobalRenderer = forwardRef<ToastRendererRef>((_, ref) => {
  const [api, element] = useInternalToast();

  useImperativeHandle(
    ref,
    () => {
      return { manager: { ...api } };
    },
    [api],
  );

  return element;
});

export function open(
  type: `${ToastVariant}`,
  message: ReactNode,
  props: Omit<ToastProps, 'variant' | 'children'> = {},
) {
  toastQueue.push({ type, props: { children: message, ...props } });
  return flushQueue();
}

export function close(key: string) {
  toastQueue.push({ type: 'destroy', key });
  return flushQueue();
}

function flushQueue() {
  if (!control) {
    const container = document.createElement('div');
    container.id = 'ans-toast-container';

    document.body.appendChild(container);

    const root = createRoot(container);

    const newControl: ToastControl = {
      root,
    };

    control = newControl;

    root.render(
      <ThemeProvider theme={DrovaLight}>
        <GlobalRenderer
          ref={(node) => {
            const { manager } = node ?? {};
            Promise.resolve().then(() => {
              if (!newControl.manager && manager) {
                newControl.manager = manager;
                flushQueue();
              }
            });
          }}
        />
      </ThemeProvider>,
    );

    return `ans-toast-1`;
  }

  if (!control.manager) {
    return '';
  }

  let toastKey = '';
  for (const toast of toastQueue) {
    if (toast.type === 'destroy') {
      control.manager?.destroy(toast.key);
    } else {
      toastKey = control.manager?.open({ ...toast.props, variant: toast.type }) ?? '';
    }
  }

  toastQueue = [];
  return toastKey;
}

import { Icon, SvgProps } from './icon';

export const ChevronExpanded = (props: SvgProps) => (
  <Icon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            d="M269.48-103.72a2.31,2.31,0,0,1-2.32-2.28,2.3,2.3,0,0,1,2.32-2.29h2.31V-106a2.3,2.3,0,0,1-2.31,2.28"
            clipRule="evenodd"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <path d="M272.21-108.71v-4.57a4.49,4.49,0,0,1,4.63,4.57Z" clipRule="evenodd" />
        </clipPath>
        <clipPath id="clip-path-3">
          <path d="M274.61-103.72a2.28,2.28,0,0,1-2.4-2.23v-2.34h4.63v4.57Z" clipRule="evenodd" />
        </clipPath>
        <clipPath id="clip-path-4">
          <path
            d="M271.79-111a2.3,2.3,0,0,1-2.32,2.29,2.29,2.29,0,0,1-2.31-2.29,2.29,2.29,0,0,1,2.31-2.28,2.3,2.3,0,0,1,2.32,2.28"
            clipRule="evenodd"
          />
        </clipPath>
      </defs>
      <g id="chevron-expanded">
        <polygon points="19 7 12 14 5 7 5 10.83 12 17.83 19 10.83 19 7" />
      </g>
    </svg>
  </Icon>
);

import * as React from 'react';

import type { DynamicFormContextType } from './types';

export const DynamicFormContext = React.createContext<DynamicFormContextType<any, any>>(
  undefined as any,
);

DynamicFormContext.displayName = 'DynamicFormContext';

export const DynamicFormProvider = DynamicFormContext.Provider;
export const DynamicFormConsumer = DynamicFormContext.Consumer;

export function useDynamicFormContext<T, P extends any[]>() {
  const context = React.useContext<DynamicFormContextType<T, P>>(DynamicFormContext);

  if (context === undefined) {
    throw new Error('useDynamicFormContext must be used within a DynamicFormProvider');
  }

  return context;
}

import { createContext } from 'react';

export type TMenuContext = {
  closeMenu: () => void;
};

export const MenuContext = createContext<TMenuContext>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeMenu: () => {},
});

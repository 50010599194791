import { PropsWithChildren } from 'react';

type Size = 'Small' | 'Medium' | 'Large' | 'XLarge' | 'XXLarge';

export type SvgProps = Omit<IconPropType, 'children'>;

type IconPropType = {
  /** Sets size of the icon */
  size?: Size;
  /** Sets icon color */
  color?: string;
  /** Sets screen reader text */
  label?: string;
  /** Inactive state */
  inactive?: boolean;
  viewBox?: string;
  className?: string;
  id?: string;
};

function getSize(size: Size) {
  switch (size) {
    case 'Small':
      return 13;
    case 'Medium':
      return 20;
    case 'Large':
      return 24;
    case 'XLarge':
      return 32;
    case 'XXLarge':
      return 60;
    default:
      return 20;
  }
}

const Icon = ({
  size = 'Medium',
  color,
  label,
  inactive = false,
  id,
  children,
  viewBox,
  className,
}: PropsWithChildren<IconPropType>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role={label ? undefined : 'presentation'}
    className={className}
    aria-label={label}
    viewBox={viewBox || `0 0 ${getSize(size)} ${getSize(size)}`}
    fill={color}
    focusable="false"
    id={id}
    height={getSize(size)}
    width={getSize(size)}
    opacity={inactive ? 0.35 : 1}
  >
    {children}
  </svg>
);

export { Icon };

import DoneIcon from '@mui/icons-material/Done';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import { type CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel, { type FormControlLabelProps } from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ErrorMessage, Field, type FieldProps, getIn, useField, useFormikContext } from 'formik';
import * as React from 'react';
import { MaxLengthField } from '~/constants/shared';
import { useBoolean } from '~/hook/use-boolean';
import { useResetPassword } from '~/hook/use-profile';
import { useJobTitleGroups, useSearchLocation } from '~/hook/use-reference';
import type { DetailedProfileInfo, FormValues, Location, Option } from '~/types';
import { useDebounce } from '~/utils/misc';
import { fieldDataFromSsoProvider, getDefaultDropdownValues } from '~/utils/profile';

import { Autocomplete } from '@ansarada/components/Autocomplete';
import { Checkbox } from '@ansarada/components/Checkbox';
import { TextField } from '@ansarada/components/TextField';

import { FieldAuthorization } from './field-authorization';
import { Progress } from './progress';

type ProfileFormInputsProps = {
  profileValues: FormValues;
  hideExistingValues?: boolean;
  hideTermsAndConditions?: boolean;
};

function ProfileFormInputs({
  hideExistingValues = false,
  hideTermsAndConditions = false,
  profileValues,
}: ProfileFormInputsProps) {
  const shouldBeReadOnly = (fieldName: string) =>
    fieldDataFromSsoProvider(profileValues, fieldName);

  const { defaultLocation, defaultSpecialty, defaultTitle } =
    getDefaultDropdownValues(profileValues);

  return (
    <Stack spacing={4}>
      <FieldAuthorization allowedField="FirstName" existValueCheck={hideExistingValues}>
        <Field
          name="firstName"
          label="First name"
          inputProps={{
            'data-test-id': 'first-name',
            'data-lpignore': 'true',
            maxLength: MaxLengthField.FirstName,
          }}
          disabled={shouldBeReadOnly('firstName')}
          component={TextFormField}
        />
      </FieldAuthorization>

      <FieldAuthorization allowedField="LastName" existValueCheck={hideExistingValues}>
        <Field
          name="lastName"
          label="Last name"
          inputProps={{
            'data-test-id': 'last-name',
            'data-lpignore': 'true',
            maxLength: MaxLengthField.LastName,
          }}
          component={TextFormField}
          disabled={shouldBeReadOnly('firstName')}
        />
      </FieldAuthorization>

      <FieldAuthorization allowedField="CompanyName" existValueCheck={hideExistingValues}>
        <Field
          name="companyName"
          label="Company"
          inputProps={{
            'data-test-id': 'company-name',
            'data-lpignore': 'true',
            maxLength: MaxLengthField.Company,
          }}
          component={TextFormField}
        />
      </FieldAuthorization>

      <FieldAuthorization allowedField="JobTitle" existValueCheck={hideExistingValues}>
        <JobTitleField defaultSpecialty={defaultSpecialty} defaultTitle={defaultTitle} />
      </FieldAuthorization>

      <FieldAuthorization allowedField="Location" existValueCheck={hideExistingValues}>
        <LocationField defaultLocation={defaultLocation} />
      </FieldAuthorization>

      <FieldAuthorization allowedField="PhoneNumber" existValueCheck={hideExistingValues}>
        <Field
          name="phoneNumber"
          label="Enter your mobile number to help us secure your account"
          inputProps={{
            'data-test-id': 'phone-number',
            'data-lpignore': 'true',
            maxLength: MaxLengthField.PhoneNumber,
          }}
          component={TextFormField}
        />
      </FieldAuthorization>

      <FieldAuthorization allowedField="Password" existValueCheck={hideExistingValues}>
        <PasswordField
          isSso={profileValues.isSso}
          hasPassword={profileValues?.hasExistingPassword || false}
        />
      </FieldAuthorization>

      {!hideTermsAndConditions && (
        <Field
          type="checkbox"
          name="termsAndConditionsAccepted"
          labelProps={{
            label: (
              <Typography variant="caption">
                I have read and I agree to the&nbsp;
                <Link
                  href="https://www.drova.com/privacy"
                  target="_blank"
                  rel="noopener"
                >
                  Terms and Conditions
                </Link>
                ,&nbsp;
                <Link href="https://www.drova.com/privacy" target="_blank" rel="noopener">
                  Cookie Policy
                </Link>
                &nbsp; and&nbsp;
                <Link href="https://www.drova.com/privacy" target="_blank" rel="noopener">
                  Privacy Policy
                </Link>
                .
              </Typography>
            ),
          }}
          component={CheckboxFormField}
        />
      )}
    </Stack>
  );
}

function TextFormField({
  field,
  form: { touched, errors, isSubmitting },
  ...props
}: FieldProps & React.ComponentProps<typeof TextField>) {
  const errorText = getIn(touched, field.name) && getIn(errors, field.name);

  return (
    <TextField
      {...props}
      fullWidth
      disabled={isSubmitting || props.disabled}
      errorMessage={errorText}
      error={!!errorText}
      inputProps={{ ...props.inputProps, 'data-ansarada-ccd': true }}
      {...field}
    />
  );
}

type CheckboxFormFieldType = FieldProps &
  CheckboxProps & {
    labelProps: Omit<FormControlLabelProps, 'checked' | 'name' | 'value' | 'control'>;
  };

function CheckboxFormField({
  field,
  form: { isSubmitting },
  disabled,
  labelProps,
  ...props
}: CheckboxFormFieldType) {
  const checkboxProps = {
    disabled: disabled ?? isSubmitting,
    ...field,
    ...props,
  };

  return (
    <Box>
      <FormControlLabel control={<Checkbox {...checkboxProps} />} {...labelProps} />
    </Box>
  );
}

function JobTitleField({
  defaultSpecialty,
  defaultTitle,
}: {
  defaultSpecialty: Option | null;
  defaultTitle: Option | null;
}) {
  const { setFieldValue } = useFormikContext<DetailedProfileInfo>();
  const {
    jobTitles,
    specialties,
    selectedSpecialty,
    selectedTitle,
    setSelectedSpecialty,
    setSelectedTitle,
  } = useJobTitleGroups({ defaultSpecialty, defaultTitle });

  const handleChangeSpecialty = (
    _: React.SyntheticEvent<Element, Event>,
    newValue: NonNullable<string | Option> | (string | Option)[],
  ) => {
    if (typeof newValue === 'string' || Array.isArray(newValue)) return;

    if (newValue) {
      setSelectedSpecialty(newValue);
      setFieldValue('jobTitleGroupName', newValue.value);
      setSelectedTitle(null);
      setFieldValue('jobTitleId', null);
    }
  };

  const handleChangeTitle = (
    _: React.SyntheticEvent<Element, Event>,
    newValue: NonNullable<string | Option> | (string | Option)[],
  ) => {
    if (typeof newValue === 'string' || Array.isArray(newValue)) return;

    if (newValue) {
      const newJobTitleId = parseInt(newValue.value, 10);
      setSelectedTitle(newValue);
      setFieldValue('jobTitleId', newJobTitleId);
    }
  };

  return (
    <>
      <Autocomplete
        noOptionsText="No options"
        value={selectedSpecialty as Option}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        getOptionLabel={(option) => {
          if (typeof option === 'string') return option;
          return option.label;
        }}
        onChange={handleChangeSpecialty}
        options={specialties}
        disableClearable
        renderInput={(params) => {
          return (
            <TextField
              label="Organisation Type"
              {...params}
              inputProps={{
                ...params.inputProps,
                'data-test-id': 'job-title-group-name',
              }}
              sx={{
                label: {
                  whiteSpace: 'wrap',
                },
              }}
            />
          );
        }}
      />

      <Autocomplete
        noOptionsText="No options"
        value={selectedTitle as Option}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        getOptionLabel={(option) => {
          if (typeof option === 'string') return option;
          return option.label;
        }}
        options={jobTitles}
        onChange={handleChangeTitle}
        disabled={!selectedSpecialty}
        disableClearable
        renderInput={(params) => (
          <TextField
            label="Title"
            {...params}
            inputProps={{
              ...params.inputProps,
              'data-test-id': 'job-title-name',
            }}
            InputProps={{ ...params.InputProps }}
            sx={{
              label: {
                whiteSpace: 'wrap',
              },
            }}
          />
        )}
      />
    </>
  );
}

function LocationField({ defaultLocation }: { defaultLocation: Location | null }) {
  const { locations, setQueryValue, isLoading, setSelectedLocation, selectedLocation } =
    useSearchLocation({ defaultLocation });

  const [, meta, helpers] = useField('locationId');
  const { isSubmitting } = useFormikContext();

  const errorText = meta.touched && meta.error;
  const syncSearchQuery = useDebounce((_: React.SyntheticEvent<Element, Event>, value: string) => {
    setQueryValue(value);
  }, 400);

  return (
    <Autocomplete
      freeSolo
      loading={isLoading}
      value={selectedLocation?.locationName ?? ''}
      isOptionEqualToValue={(option, value) => {
        return option.locationName === value.locationName;
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return option.locationName;
      }}
      options={locations}
      filterOptions={(x) => x}
      includeInputInList
      selectOnFocus
      clearOnBlur
      onInputChange={syncSearchQuery}
      onChange={(_, newValue) => {
        if (typeof newValue === 'string' || Array.isArray(newValue)) return;
        if (newValue && typeof newValue === 'object') {
          helpers.setValue(newValue.locationId);
          setSelectedLocation(newValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          label={"What's the nearest city where you are?"}
          {...params}
          fullWidth
          errorMessage={errorText as string}
          error={!!errorText}
          onBlur={() => {
            helpers.setTouched(true);
          }}
          disabled={isSubmitting}
          inputProps={{
            ...params.inputProps,
            'data-ansarada-ccd': true,
            'data-test-id': 'location-name',
          }}
          InputProps={{ ...params.InputProps }}
          sx={{
            label: {
              whiteSpace: 'wrap',
            },
          }}
        />
      )}
      loadingText={
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Progress color="inherit" size={20} />
        </Box>
      }
    />
  );
}

function PasswordField({ isSso, hasPassword }: { isSso: boolean; hasPassword: boolean }) {
  const { resetPassword, resetPasswordSent } = useResetPassword();
  const [showPassword, { toggle: toggleShowPassword }] = useBoolean();

  const handleSentResetPassword = async () => {
    await resetPassword.mutateAsync();
  };

  if (isSso) {
    return (
      <Typography color="grey.700" variant="body2">
        Password is managed by your organisation
      </Typography>
    );
  }

  if (hasPassword) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <Link
          component="button"
          type="button"
          onClick={handleSentResetPassword}
          disabled={resetPassword.isLoading || resetPasswordSent}
          sx={{ marginRight: 2 }}
          color={resetPasswordSent ? 'grey.700' : undefined}
          underline={resetPasswordSent ? 'none' : undefined}
        >
          Send reset password email
        </Link>
        {resetPassword.isLoading && <Progress size={20} />}
        {resetPasswordSent && <DoneIcon color="primary" />}
      </Box>
    );
  }

  return (
    <Field name="password">
      {({ field, form: { touched, errors, isSubmitting } }: FieldProps) => (
        <Box>
          <TextField
            {...field}
            fullWidth
            type={showPassword ? 'text' : 'password'}
            label="Password"
            variant="outlined"
            helperText={(touched['password'] && errors['password']) as string}
            error={!!(touched['password'] && errors['password'])}
            inputProps={{
              'data-test-id': 'password',
              'data-ansarada-ccd': true,
            }}
            disabled={isSubmitting}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    data-test-id="show-password-icon"
                    aria-label="toggle password visibility"
                    onClick={toggleShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <ErrorMessage name="password">
            {() => (
              <Typography
                variant="caption"
                component="p"
                color="error.main"
                sx={{ paddingX: '14px' }}
              >
                Hint: Password must contain at least 8 characters, including a lowercase letter, an
                uppercase letter, and a number. It cannot contain part of your email.
              </Typography>
            )}
          </ErrorMessage>
        </Box>
      )}
    </Field>
  );
}

export { ProfileFormInputs, TextFormField };

import {
  Box,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  SxProps,
  Typography,
  checkboxClasses,
  styled,
  svgIconClasses,
} from '@mui/material';
import cls from 'classnames';
import { ReactNode, forwardRef, useId, useRef } from 'react';

import { UtilityProps } from '../../utils/prop';
import { mergeRefs } from '../../utils/ref';
import { Icon } from './internal/Icon';

type BaseProps = {
  /** The label of this Checkbox */
  label?: ReactNode;

  /** The props passed to Label of this Checkbox */
  labelProps?: SxProps;

  /** If `true`, Checkbox will turn into erroneous state*/
  error?: boolean;
} & UtilityProps;

type Props = BaseProps & Omit<MuiCheckboxProps, 'checkedIcon' | 'icon' | keyof BaseProps>;

const classes = {
  error: 'Checkbox__root--error',
  disabled: 'Checkbox__root--disabled',

  label: 'Checkbox__label',
};

/** This component inherits [MUI Checkbox's API](https://mui.com/material-ui/api/checkbox/)\
 * See the [API documented on Storybook](https://ansarada-design-system.vercel.app/?path=/story/elements-form--check-story)
 */
const Checkbox = styled(
  forwardRef<HTMLButtonElement, Props>(
    ({ className, label, labelProps, error, 'data-test-id': testId, ...props }: Props, ref) => {
      const labelRef = useRef<HTMLLabelElement>(null);

      const generatedId = useId();
      const id = props.id || generatedId;

      return (
        <Box
          className={cls(className, error && classes.error, props.disabled && classes.disabled)}
          data-test-id={testId}
        >
          <MuiCheckbox
            {...props}
            id={id}
            ref={mergeRefs(ref)}
            icon={<Icon />}
            indeterminateIcon={<Icon />}
            checkedIcon={<Icon />}
            disableRipple
          />

          {label && (
            <Typography
              className={cls(classes.label)}
              component="label"
              variant="body2"
              htmlFor={id}
              ref={labelRef}
              sx={labelProps}
            >
              {label}
            </Typography>
          )}
        </Box>
      );
    },
  ),
)(({ theme: { palette } }) => {
  return {
    '&': {
      display: 'flex',
      alignItems: 'center',

      // Checkbox has 9px of padding already
      gap: '3px',

      [`.${svgIconClasses.root}`]: {
        path: { display: 'none', stroke: palette.$dawn.$500, fill: 'none' },
        line: { display: 'none', stroke: palette.$dawn.$500, fill: 'none' },
        rect: {
          fill: palette.$white,
          stroke: palette.$solar.$800,
        },
      },

      [`.${checkboxClasses.checked}`]: {
        [`.${svgIconClasses.root}`]: {
          path: { display: 'unset' },
          rect: {
            fill: palette.$solar.$500,
          },
        },
      },

      [`.${checkboxClasses.indeterminate}`]: {
        [`&.${checkboxClasses.checked}.${checkboxClasses.checked}`]: {
          line: { display: 'none' },
        },

        [`.${svgIconClasses.root}`]: {
          line: { display: 'unset' },
          rect: {
            fill: palette.$solar.$500,
          },
        },
      },

      '&:hover': {
        [`.${classes.label}`]: {
          color: palette.$text.medium,
        },
      },

      [`&.${classes.error}`]: {
        [`.${classes.label}`]: {
          color: `${palette.$red.$500} !important`,
        },

        [`.${svgIconClasses.root}`]: {
          rect: {
            stroke: palette.$red.$500,
            fill: palette.$white,
          },
        },

        [`.${checkboxClasses.checked}, .${checkboxClasses.indeterminate}`]: {
          [`.${svgIconClasses.root}`]: {
            rect: {
              stroke: palette.$red.$500,
              fill: palette.$red.$50,
            },
          },
        },
      },

      [`&.${classes.disabled}`]: {
        [`.${classes.label}`]: {
          color: `${palette.$earth.$200} !important`,
        },

        [`.${svgIconClasses.root}`]: {
          'rect, path': {
            stroke: palette.$earth.$200,
          },
        },

        [`.${checkboxClasses.checked}, .${checkboxClasses.indeterminate}`]: {
          [`.${svgIconClasses.root}`]: {
            'path, line': {
              stroke: palette.$light_grey.$500,
            },
            rect: {
              stroke: palette.$light_grey.$500,
              fill: palette.$white,
            },
          },
        },
      },

      [`& .${classes.label}`]: {
        fontSize: '12px',
        userSelect: 'none',

        // Workaround for font problem
        transform: 'translateY(0.5px)',
      },
    },
  } satisfies SxProps;
});

export { Checkbox, classes as checkboxClasses };

export const DROVA_FONT_CONFIG = {
  neutrifRegular: {
    fileName: 'NeutrifPro-Regular.ttf',
    family: 'NeutrifPro Regular',
    fallback: `"Times New Roman", Times, serif`,
  },
  neutrifRegularItalic: {
    fileName: 'NeutrifPro-RegularItalic.ttf',
    family: 'NeutrifPro RegularItalic',
  },
  neutrifSemiBold: {
    fileName: 'NeutrifPro-SemiBold.ttf',
    family: 'NeutrifPro SemiBold',
    fallback: `"Times New Roman", Times, serif`,
  },
  neutrifSemiBoldItalic: {
    fileName: 'NeutrifPro-SemiBoldItalic.ttf',
    family: 'NeutrifPro SemiBoldItalic',
    fallback: `"Times New Roman", Times, serif`,
  },

  // ---- Reckless Neue ----

  recklessNeueRegular: {
    fileName: 'RecklessNeue-Regular.ttf',
    family: 'RecklessNeue Regular',
  },
};

const FontExtensionFormat = {
  woff2: 'woff2',
  woff: 'woff',
  ttf: 'truetype',
  otf: 'opentype',
};

type FontExtension = keyof typeof FontExtensionFormat;

const BASE_STATIC_URL = 'https://static.cdn.drova.com/assets/fonts';

function getFontUrl(filename: string) {
  const [_, extension] = filename.split('.') as FontExtension[];
  return `url(${BASE_STATIC_URL}/${filename}) format('${FontExtensionFormat[extension]}')`;
}

export function generateFontFace(type: keyof typeof DROVA_FONT_CONFIG) {
  const { family, fileName } = DROVA_FONT_CONFIG[type];

  return `
      @font-face {
        font-family: ${family};
        src: ${getFontUrl(fileName)};
      }
    `;
}

import { Typography, TypographyProps } from '@mui/material';
import { OverridableComponent, OverrideProps } from '@mui/material/OverridableComponent';
import { useLayoutEffect, useRef, useState } from 'react';

import { UtilityProps } from '../../utils/prop';
import { Tooltip, Props as TooltipProps } from '../Tooltip';

type BaseProps = {
  TooltipProps?: Omit<TooltipProps, 'title' | 'children' | 'disabled'>;
} & UtilityProps;

interface TypeMap<P = {}, D extends React.ElementType = 'div'> {
  props: P & Omit<TypographyProps, keyof BaseProps> & BaseProps;
  defaultComponent: D;
}

type Props<D extends React.ElementType = TypeMap['defaultComponent'], P = {}> = OverrideProps<
  TypeMap<P, D>,
  D
>;

const TruncatedTypographyWithTooltip: OverridableComponent<TypeMap> = ({
  TooltipProps = {},
  children,
  ...props
}: Props) => {
  const typoEl = useRef<HTMLDivElement>(null);
  const [disableTooltip, setDisableTooltip] = useState(false);

  useLayoutEffect(() => {
    const typo = typoEl.current;
    if (!typo) return;

    setDisableTooltip(typo.clientWidth >= typo.scrollWidth);
  }, [children]);

  return (
    <Tooltip textType="Long" {...TooltipProps} title={children ?? ''} disabled={disableTooltip}>
      <Typography noWrap {...props} ref={typoEl}>
        {children}
      </Typography>
    </Tooltip>
  );
};

export { TruncatedTypographyWithTooltip };

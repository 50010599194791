import { useRoutes } from 'react-router-dom';
import { AppProviders } from '~/context';
import NotFound from '~/routes/404';
import Callback from '~/routes/callback';
import Profile from '~/routes/profile';
import Signup from '~/routes/signup';

import { ResetMFA } from './routes/mfa.reset';
import { ResetMFAComplete } from './routes/mfa.reset-complete';

export const App = () => {
  return (
    <AppProviders>
      <AppRoutes />
    </AppProviders>
  );
};

export const AppRoutes = () => {
  const element = useRoutes([
    {
      path: '/signup',
      element: <Signup />,
    },
    {
      path: '/profile',
      element: <Profile />,
    },
    {
      path: '/grc/profile',
      element: <Profile assetType="grc" />,
    },
    {
      path: '/signup-callback',
      element: <Callback assetType="dataroom" callbackType="signup" queryKeyCallback="state" />,
    },
    {
      path: '/login-callback',
      element: <Callback assetType="dataroom" callbackType="login" />,
    },
    {
      path: '/logout-callback',
      element: (
        <Callback
          assetType="dataroom"
          callbackType="logout"
          queryKeyRedirect="redirectAfterLogout"
        />
      ),
    },
    {
      path: '/grc/logout-callback',
      element: <Callback assetType="grc" callbackType="logout" queryKeyCallback="grc-origin-web" />,
    },
    {
      path: '/grc/login-callback',
      element: <Callback assetType="grc" callbackType="login" queryKeyCallback="grc-origin-web" />,
    },
    {
      path: '/mfa/reset',
      element: <ResetMFA />,
    },
    {
      path: '/reset-mfa',
      element: <ResetMFAComplete />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ]);

  return element;
};

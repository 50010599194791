import * as React from 'react';
import type { FormValues, RequiredProfileField, RequiredProfileFields } from '~/types';
import { fieldValueExists } from '~/utils/profile';

import { useDynamicFormContext } from './dynamic-form';

type FieldAuthorizationProps = {
  forbiddenFallback?: React.ReactNode;
  children: React.ReactNode;
  allowedField: RequiredProfileField;
  existValueCheck?: boolean;
};

const useFieldAuthorization = () => {
  const {
    fields,
    initialValues,
    forceDisplayFields = [],
  } = useDynamicFormContext<FormValues, RequiredProfileFields>();

  const checkAccess = React.useCallback(
    ({ allowedField }: { allowedField: RequiredProfileField }) => fields.includes(allowedField),
    [fields],
  );

  const checkHasValue = React.useCallback(
    ({ allowedField }: { allowedField: RequiredProfileField }) => {
      if (allowedField === 'JobTitle') {
        return fieldValueExists(initialValues, 'jobTitleId');
      }

      if (allowedField === 'Location') {
        return fieldValueExists(initialValues, 'locationId');
      }

      const camelCaseField = allowedField.charAt(0).toLowerCase() + allowedField.slice(1);

      return fieldValueExists(initialValues, camelCaseField as keyof FormValues);
    },
    [initialValues],
  );

  const hasForceDisplayField = React.useCallback(
    ({ allowedField }: { allowedField: RequiredProfileField }) =>
      forceDisplayFields.includes(allowedField),
    [forceDisplayFields],
  );

  return { checkAccess, checkHasValue, hasForceDisplayField };
};

export const FieldAuthorization = ({
  forbiddenFallback = null,
  children,
  allowedField,
  existValueCheck = true,
}: FieldAuthorizationProps) => {
  const { checkAccess, checkHasValue, hasForceDisplayField } = useFieldAuthorization();

  if (hasForceDisplayField({ allowedField })) {
    return <>{children}</>;
  }

  let canAccess = checkAccess({ allowedField });

  if (existValueCheck) {
    canAccess = checkAccess({ allowedField }) && !checkHasValue({ allowedField });
  }

  return <>{canAccess ? children : forbiddenFallback}</>;
};

import { useEffect, useState } from 'react';
import { useClient } from '~/context/auth-context';
import type { CompleteProfileDto } from '~/types';

type UseResetMFAOptions = {
  variables: CompleteProfileDto;
  options: {
    onSuccess: () => void;
  };
};

export const useUpdateProfile = ({ variables, options }: UseResetMFAOptions) => {
  const client = useClient<void, CompleteProfileDto>();

  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await client('signup/v1/profiles/update', {
          data: variables,
          method: 'PATCH',
        });
        setIsSuccess(true);
        options.onSuccess();
      } catch {
        setIsSuccess(false);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [client, variables, options]);

  return { isLoading, isSuccess } as const;
};

import { ChevronRightRounded, CloseOutlined } from '@mui/icons-material';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  SxProps,
  Typography,
  autocompleteClasses,
  formHelperTextClasses,
  iconButtonClasses,
  inputBaseClasses,
  outlinedInputClasses,
  paperClasses,
  popoverClasses,
  selectClasses,
  styled,
  svgIconClasses,
  textFieldClasses,
} from '@mui/material';
import cls from 'classnames';
import { merge } from 'lodash';
import { ComponentProps, MouseEvent, ReactNode, forwardRef, useId, useRef } from 'react';

import { DROVA_FONT_CONFIG } from '@ansarada/mui-themes/lib/drova/fonts';

import { UtilityProps } from '../../utils/prop';
import { mergeRefs } from '../../utils/ref';
import { Spinner } from '../Spinner';
import { applyCcdToChildrenAndAddNoneOption, classes, getSelectChildrenLabels } from './utils';

type TextFieldProps = {
  /** The label of the component */
  label?: ReactNode;

  /** If `true`, show `None` option in the dropdown of `Select` mode */
  noneOption?: boolean;

  /** The icon to be displayed at the end of input */
  icon?: ReactNode;

  /** The function runs when clicking on icon */
  onIconClick?: (e: MouseEvent<HTMLButtonElement>) => void;

  /** If `true`, the component will turn into erroneous state */
  error?: boolean;

  /** The message to be displayed when being in erroneous state */
  errorMessage?: ReactNode;

  /** If `true`, the component will turn into loading state */
  loading?: boolean;

  /** If `true`, a clear button will be displayed at the end of input allowing clearing the value of the component */
  clearable?: boolean;

  /** The function runs when clear button is clicked */
  onClear?: () => void;
} & UtilityProps;

type Props = TextFieldProps & Omit<ComponentProps<typeof MuiTextField>, '' | keyof TextFieldProps>;

/** This component inherits [MUI TextField's API](https://mui.com/material-ui/api/text-field/)\
 * See the [API documented on Storybook](https://ansarada-design-system.vercel.app/?path=/story/elements-form-text--single-line)
 */
const TextField = styled(
  forwardRef<HTMLDivElement, Props>(
    (
      {
        label,
        icon,
        className,
        error,
        children,
        errorMessage,
        noneOption,
        clearable,
        loading = false,
        onIconClick,
        onClear,
        'data-ansarada-ccd': dataAnsaradaCcd,
        ...props
      }: Props,
      ref,
    ) => {
      const generatedId = useId();
      const id = props.id || generatedId;

      const internalInputRef = useRef<HTMLInputElement>(null);

      function clearAndFocus() {
        onClear?.();
        internalInputRef.current?.focus();
      }

      function getEndAdornment() {
        if (loading)
          return (
            <InputAdornment position="end">
              <Spinner />
            </InputAdornment>
          );

        if (props.select) return null;

        const clearIcon = clearable ? (
          <IconButton color="ghostWhite" disabled={props.disabled} onClick={clearAndFocus}>
            <CloseOutlined />
          </IconButton>
        ) : null;

        const endIcon = icon ? (
          <IconButton color="ghostWhite" disabled={props.disabled} onClick={onIconClick}>
            {icon}
          </IconButton>
        ) : null;

        const propsEndAdornment = props.InputProps?.endAdornment;
        if (clearable || icon || propsEndAdornment) {
          return (
            <InputAdornment position="end">
              {clearIcon}
              {endIcon}
              {propsEndAdornment}
            </InputAdornment>
          );
        }

        return propsEndAdornment;
      }

      return (
        <Box
          className={cls(
            className,
            classes.root,
            error && classes.error,
            props.disabled && classes.disabled,
            loading && classes.loading,
          )}
        >
          {(label || errorMessage) && (
            <Typography
              noWrap
              component="label"
              variant="caption"
              htmlFor={id}
              className={classes.label}
            >
              {error ? errorMessage || label : label}
            </Typography>
          )}

          <MuiTextField
            {...merge(
              {
                SelectProps: {
                  IconComponent: ({ className }) => {
                    if (loading) return null;
                    return <ChevronRightRounded className={cls(className, classes.selectIcon)} />;
                  },
                  MenuProps: {
                    className: classes.selectMenu,
                    disablePortal: true,
                  },

                  ...(!!props.placeholder && {
                    displayEmpty: true,
                    renderValue: (v) => {
                      const value = props.value ?? v;
                      const labels = getSelectChildrenLabels(children);

                      if (typeof value === 'string' && Boolean(value)) {
                        return labels.find((item) => item?.value === value)?.label;
                      }

                      if (Array.isArray(value)) {
                        return value
                          .map((value) => labels.find((item) => item?.value === value)?.label)
                          .join(', ');
                      }

                      return <span className={classes.placeholder}>{props.placeholder}</span>;
                    },
                  }),
                },
              } as typeof props,
              props,
              {
                id,
                ref,
                error,
                label: null,
                children: props.select
                  ? applyCcdToChildrenAndAddNoneOption(children, dataAnsaradaCcd, noneOption)
                  : children,
              } as Props,
            )}
            inputProps={{
              ...props.inputProps,
              ref: mergeRefs(internalInputRef, props.inputProps?.['ref']),
            }}
            InputProps={{
              ...props.InputProps,
              endAdornment: getEndAdornment(),
            }}
          />
        </Box>
      );
    },
  ),
)(({
  theme: {
    spacing,
    palette,
    shape: { borderRadius },
  },
}) => {
  return {
    // --------- BASE STYLING ---------

    display: 'flex',
    flexDirection: 'column',

    [`.${classes.label}`]: {
      color: palette.$text.medium,
    },

    [`.${iconButtonClasses.root}`]: {
      padding: '4px',

      [`.${svgIconClasses.root}`]: {
        fontSize: '24px',
      },
    },

    [`.${textFieldClasses.root}`]: {
      minHeight: '36px',

      [`.${formHelperTextClasses.root}`]: {
        color: palette.$text.light,
        marginInline: '0px',
      },

      [`.${inputBaseClasses.root}`]: {
        paddingRight: spacing(2),
        borderRadius: '4px',

        [`:not(.${inputBaseClasses.multiline})`]: {
          height: '36px',
        },

        [`.${inputBaseClasses.input}`]: {
          fontFamily: DROVA_FONT_CONFIG.neutrifRegular.family,
          color: palette.$text.base,
          paddingInline: spacing(3),
          paddingBlock: '6.5px',

          // Plus padding block -> 36px height
          lineHeight: '23px',

          [`::placeholder, .${classes.placeholder}`]: {
            fontFamily: DROVA_FONT_CONFIG.neutrifRegular.family,
            color: palette.$text.light,
            opacity: 1,
          },
        },

        [`.${outlinedInputClasses.notchedOutline}`]: {
          borderColor: palette.$solar.$800,
        },

        [`&:has(.${inputBaseClasses.input}:focus, .${inputBaseClasses.input}:focus-visible), &:has(.${popoverClasses.root})`]:
          {
            [`.${outlinedInputClasses.notchedOutline}`]: {
              borderColor: palette.$solar.$900,
              borderWidth: '1px',
            },
          },
      },
    },

    ':hover': {
      [`.${textFieldClasses.root}`]: {
        [`.${outlinedInputClasses.notchedOutline}`]: {
          borderColor: palette.$solar.$900,
        },
      },
    },

    // --------- ERROR STYLING ---------

    [`&.${classes.error}`]: {
      [`.${classes.label}`]: {
        color: palette.$text.error,
      },

      [`.${outlinedInputClasses.notchedOutline}`]: {
        borderColor: `${palette.$red.$500} !important`,
      },
    },

    // --------- DISABLED STYLING ---------

    // Increase specificity to override error state
    [`&.${classes.disabled}.${classes.disabled}`]: {
      [`.${classes.label}`]: {
        color: palette.$text.disabled,
      },

      [`.${outlinedInputClasses.notchedOutline}`]: {
        borderColor: `${palette.$earth.$100} !important`,
      },
    },

    // --------- LOADING STYLING ---------

    [`&.${classes.loading}`]: {
      [`.${textFieldClasses.root}`]: {
        [`.${inputBaseClasses.root}`]: {
          paddingRight: spacing(3),

          [`&:has(.${autocompleteClasses.input})`]: {
            paddingRight: `${spacing(3)} !important`,
          },
        },
      },
    },

    // --------- MULTILINE STYLING ---------

    [`.${textFieldClasses.root} .${inputBaseClasses.multiline}`]: {
      padding: '0px',
    },

    // --------- SELECT STYLING ---------

    [`.${inputBaseClasses.root} .${selectClasses.select}`]: {},

    [`.${classes.selectIcon}`]: {
      color: palette.$text.base,
      rotate: '90deg',

      [`&.Mui-disabled`]: {
        color: palette.$text.disabled,
      },
    },

    [`.${classes.selectMenu} .${paperClasses.root}`]: {
      marginTop: spacing(2),
      boxShadow: `inset 0 0 0 1px ${palette.$solar.$800} !important`,
      border: 'none',
      padding: spacing(3),
      borderRadius: `${borderRadius} !important`,
    },

    // --------- AUTOCOMPLETE STYLING ---------

    [`.${inputBaseClasses.root}:has(.${autocompleteClasses.input})`]: {
      paddingBlock: '6.5px !important',
      paddingRight: '48px !important',

      [`.${autocompleteClasses.endAdornment}`]: {
        right: spacing(2),
        top: 'unset',
      },
    },
  } satisfies SxProps;
});

export { TextField };

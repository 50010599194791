import { CssBaseline, Link, ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router } from 'react-router-dom';
import { MessageSection } from '~/components/sections/message-section';
import { queryClient } from '~/lib/react-query';

import { DrovaLight } from '@ansarada/mui-themes/lib/drova';

import { AuthProvider } from './auth-context';

type AuthProviderProps = {
  children: React.ReactNode;
};

function FullPageErrorFallback({ error }: { error: Error }) {
  const ContactSupportLink = (
    <Link href="https://www.drova.com/contact-us">contact us</Link>
  );

  return (
    <MessageSection title="Unknown Error" subtitle={error.message}>
      <p data-test-id="error">Please {ContactSupportLink} for more information!</p>
    </MessageSection>
  );
}

function AppProviders({ children }: AuthProviderProps) {
  return (
    <ThemeProvider theme={DrovaLight}>
      <ErrorBoundary FallbackComponent={FullPageErrorFallback}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <CssBaseline />
            <AuthProvider>
              {children}
              <ReactQueryDevtools initialIsOpen={false} />
            </AuthProvider>
          </Router>
        </QueryClientProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export { AppProviders };

import { type UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { defaultRequiredFields } from '~/constants/shared';
import { useClient } from '~/context/auth-context';
import { BadRequestError, NotFoundError } from '~/lib/api-error';
import type {
  CompleteProfileDto,
  DetailedProfileInfo,
  EnrichInfo,
  ErrorCode,
  SignupInfo,
} from '~/types';
import { profileHasEmptyFields } from '~/utils/profile';

const useGetSignupInfo = (options?: UseQueryOptions<SignupInfo, { status: number }>) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [errorCode, setErrorCode] = React.useState<ErrorCode>();
  const client = useClient<SignupInfo>();

  const signupToken = searchParams.get('signupToken');

  React.useEffect(() => {
    if (!signupToken) {
      navigate('/404');
    }
  }, [navigate, signupToken]);

  const {
    data: signupInfo,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ['profile-info'],
    queryFn: () =>
      client('signup/v1/info/find', {
        data: {
          signupToken: signupToken ?? '',
          includeSensitiveData: true,
        },
      }).then((data) => data),
    enabled: !!signupToken,
    onError: (err) => {
      if (err instanceof NotFoundError) {
        setErrorCode('NotFound');
      } else if (err instanceof BadRequestError) setErrorCode('Invalid');
      else setErrorCode('Unknown');
    },
    useErrorBoundary: (error) => error.status >= 500,
    ...options,
  });

  return {
    isError,
    errorCode,
    hasEmptyFields: profileHasEmptyFields({
      profile: signupInfo?.profile,
      requiredFields: !signupInfo?.requiredProfileFields.length
        ? defaultRequiredFields
        : signupInfo.requiredProfileFields,
    }),
    profileInfo: signupInfo?.profile,
    requiredProfileFields: signupInfo?.requiredProfileFields ?? [],
    assetCallbackUrl: signupInfo?.signupCompletedCallbackUrl ?? '',
    signupToken: signupToken ?? '',
    isGettingSignupInfo: isLoading,
  };
};

const useCompleteSignup = ({ profileInfo }: { profileInfo?: DetailedProfileInfo }) => {
  const client = useClient();
  const isProfileHasPasswordOrSso = profileInfo?.hasExistingPassword || profileInfo?.isSso;
  const endpoint = isProfileHasPasswordOrSso ? 'signup/v1/profiles/update' : 'signup/v1';
  const method = isProfileHasPasswordOrSso ? 'PATCH' : 'POST';

  const completeSignupMutation = useMutation<unknown, { errors: unknown }, CompleteProfileDto>(
    (completeProfileInfo) =>
      client(endpoint, {
        method: method,
        data: completeProfileInfo,
      }),
  );

  return completeSignupMutation;
};

const useEnrichSignupInfo = ({
  signupToken,
  options,
}: {
  signupToken: string;
  options?: UseQueryOptions<EnrichInfo, { status: number }>;
}) => {
  const client = useClient<EnrichInfo>();

  const { data: enrichInfo, isInitialLoading } = useQuery({
    queryKey: ['enrich-info'],
    queryFn: () =>
      client('signup/v1/frictionless/token/enrich', {
        data: {
          signupToken,
        },
      }).then((data) => data),
    enabled: !!signupToken,
    useErrorBoundary: (error) => error.status >= 500,
    ...options,
  });

  return {
    enrichInfo: enrichInfo ?? ({} as EnrichInfo),
    isEnriching: isInitialLoading,
  };
};

export { useCompleteSignup, useEnrichSignupInfo, useGetSignupInfo };

import { useAuth0 } from '@auth0/auth0-react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { MessageSection } from '~/components/sections/message-section';
import appConfig from '~/config';

type SwitchAccountsSectionProps = {
  invitedEmail: string;
};

const SwitchAccountsSection = ({ invitedEmail }: SwitchAccountsSectionProps) => {
  const { user, logout } = useAuth0();
  const [searchParams] = useSearchParams();

  const signupToken = searchParams.get('signupToken');
  const redirectAfterLogout = encodeURIComponent(`/signup?signupToken=${signupToken}`);

  const Logout = (
    <Link
      onClick={() =>
        logout({
          returnTo: `${appConfig.auth.logoutRedirectUri}?redirectAfterLogout=${redirectAfterLogout}`,
        })
      }
      sx={{
        cursor: 'pointer',
      }}
    >
      logout completely
    </Link>
  );

  return (
    <MessageSection title="Oops!" subtitle="Logged-in as another user">
      <Typography variant="body2" data-test-id={`invited-email-${invitedEmail}`}>
        {`This link is for ${invitedEmail}. You're logged in as ${user?.email}.`}
        <br />
        Please {Logout} to continue.
      </Typography>
    </MessageSection>
  );
};

export { SwitchAccountsSection };

import { StyledComponent } from '@emotion/styled';
import {
  Box,
  Stack,
  SxProps,
  Typography,
  styled,
  svgIconClasses,
  typographyClasses,
} from '@mui/material';
import cls from 'classnames';
import { Children, ElementType, ReactElement, ReactNode } from 'react';

import { UtilityProps } from '../../utils/prop';
import { close, open } from './ToastRenderer';
import { ToastAction, ToastActionProps } from './internal/ToastAction';
import { classes } from './utils';

export enum ToastVariant {
  INFO = 'Informative',
  SUCCESS = 'Success',
  WARNING = 'Warning',
  ERROR = 'Error',
}

type Props = {
  /** The variant of the component */
  variant?: ToastVariant | `${ToastVariant}`;

  /** The content of the component */
  children: ReactNode;

  /** The array of actions of the component */
  actions?: ReactElement<ToastActionProps, typeof ToastAction>[];

  /** The icon of the component */
  icon?: ElementType | null;

  /** The classname of the component */
  className?: string;

  /** If `true`, the component will wrap its content when overflowed */
  wrap?: boolean;

  /** After duration of time, this notice will disappear.(seconds) */
  duration?: number;

  /** Key to identify toast in the stack eg. closeToast(toastKey) */
  toastKey?: string;

  /** The function to render elements at the action spot */
  renderActions?: () => ReactElement[];
} & UtilityProps;

/** This component inherits [MUI Box's API](https://mui.com/material-ui/api/box/)\
 * See the [API documented on Storybook](https://ansarada-design-system.vercel.app/?path=/story/elements-messages--toast-story)
 */
const Toast = styled(
  ({
    variant = ToastVariant.INFO,
    children,
    wrap = true,
    icon,
    actions = [],
    className,
    duration: _duration,
    toastKey: _toastKey,
    renderActions,
    ...props
  }: Props) => {
    const Icon = icon;

    return (
      <Box {...props} className={cls(className, classes.root, wrap && classes.wrap)}>
        {Icon && <Icon />}

        <Typography variant="caption" noWrap={!wrap}>
          <Box component="b" mr={2}>
            {variant}
          </Box>

          {children}
        </Typography>

        {(!!actions.length || renderActions) && (
          <Stack className={classes.actionContainer} direction="row" gap="6px">
            {!!actions.length && Children.toArray(actions)}
            {renderActions && Children.toArray(renderActions())}
          </Stack>
        )}
      </Box>
    );
  },
)(
  ({
    actions = [],
    icon,
    variant = ToastVariant.INFO,
    renderActions,
    theme: {
      palette,
      shape: { borderRadius },
      spacing,
    },
  }) => {
    const cols = ['1fr'];
    if (icon) {
      cols.unshift('16px');
    }

    if (actions.length || renderActions) {
      cols.push('max-content');
    }

    const variant2BgColor: Record<ToastVariant, string> = {
      [ToastVariant.ERROR]: palette.$red.$50,
      [ToastVariant.INFO]: palette.$sky_blue.$50,
      [ToastVariant.WARNING]: palette.$orange.$50,
      [ToastVariant.SUCCESS]: palette.$green.$50,
    };

    const variant2BorderColor: Record<ToastVariant, string> = {
      [ToastVariant.ERROR]: palette.$red.$600,
      [ToastVariant.INFO]: palette.$sky_blue.$600,
      [ToastVariant.WARNING]: palette.$orange.$600,
      [ToastVariant.SUCCESS]: palette.$green.$600,
    };

    return {
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: cols.join(' '),
      alignItems: 'center',
      gap: spacing(2),

      width: '360px',
      padding: spacing(4),

      borderRadius,
      border: `1px solid ${variant2BorderColor[variant]}`,

      backgroundColor: variant2BgColor[variant],

      [`&.${classes.wrap}`]: {
        alignItems: 'start',

        [`.${typographyClasses.root}`]: {
          overflowWrap: 'anywhere',
        },
      },

      [`.${svgIconClasses.root}`]: {
        fontSize: '16px',
      },
    } satisfies SxProps;
  },
) as StyledComponent<Props> & { ToastAction: typeof ToastAction };

Toast.ToastAction = ToastAction;
Toast.displayName = 'Toast';

export { Toast, ToastAction, close as closeToast, open as openToast, classes as toastClasses };

import type { FormikErrors } from 'formik';
import type {
  DetailedProfileInfo,
  ExtraRequiredProfileField,
  FormValues,
  RequiredProfileFields,
} from '~/types';

import { keys, omit, pick } from './object';

const fieldDataFromSsoProvider = (profile: FormValues, fieldName: string) =>
  profile.isSso && ['firstName', 'lastName', 'password'].includes(fieldName);

const isJobTitleFullFilled = (profile: FormValues) => {
  if (!profile) {
    return false;
  }

  // Ask to refill the jobTitle again
  if (profile.jobTitleId === 0) {
    return false;
  }

  return !!profile.jobTitleId;
};

const rawValuePresents = (profile: FormValues, fieldName: keyof FormValues) => {
  if (fieldName === 'jobTitleId') return isJobTitleFullFilled(profile);

  if (fieldName === 'password') return passwordExistsInIdentity(profile);

  if (!profile) {
    return false;
  }

  return profile && !!profile[fieldName];
};

const passwordExistsInIdentity = (profile: FormValues) =>
  !profile?.isSso && profile?.hasExistingPassword;

// Consider a field value as "exists" if:
// - it's from SSO profile, and fields are mapped in Auth0
// - or it presents in a JSON field in profile
// - or it's password field of a non-SSO profile, and hasExistingPassword === true (meaning Identity already stored password)
const fieldValueExists = (profile: FormValues, fieldName: keyof FormValues) =>
  fieldDataFromSsoProvider(profile, fieldName) || rawValuePresents(profile, fieldName);

const getDefaultDropdownValues = (values: FormValues) => {
  const defaultLocation =
    values.locationId && values.locationName
      ? {
          locationId: values.locationId,
          locationName: values.locationName,
        }
      : null;

  const defaultSpecialty = values.jobTitleGroupName
    ? {
        label: values.jobTitleGroupName,
        value: values.jobTitleGroupName,
      }
    : null;

  const defaultTitle =
    values.jobTitleId && values.jobTitleName
      ? {
          label: values.jobTitleName,
          value: String(values.jobTitleId),
        }
      : null;

  return { defaultLocation, defaultSpecialty, defaultTitle };
};

function mapDetailedProfileInfo(detailedProfileInfo: DetailedProfileInfo) {
  const formValues: Record<string, any> = { password: '' };

  const mappedJobTitleId =
    detailedProfileInfo.jobTitleId !== null ? detailedProfileInfo.jobTitleId : undefined;

  const mappedLocationId =
    detailedProfileInfo.locationId !== null ? detailedProfileInfo.locationId : undefined;

  const booleanFields = ['isSSo', 'termsAndConditionsAccepted', 'hasExistingPassword'];

  for (const [fieldName, fieldValue] of Object.entries(detailedProfileInfo)) {
    if (fieldName === 'jobTitleId') {
      formValues[fieldName] = mappedJobTitleId;
    } else if (fieldName === 'locationId') {
      formValues[fieldName] = mappedLocationId;
    } else if (booleanFields.includes(fieldName)) {
      formValues[fieldName] = Boolean(fieldValue);
    } else {
      formValues[fieldName] = fieldValue ?? '';
    }
  }

  return omit(formValues, ['countryCode', 'ssoConnectionName']) as FormValues;
}

type ErrorWithFormValue = {
  errors: FormikErrors<FormValues>;
};

function isErrorWithFormValue(error: unknown): error is ErrorWithFormValue {
  return typeof error === 'object' && error !== null && 'errors' in error;
}

function profileHasEmptyFields({
  profile,
  requiredFields,
}: {
  profile: DetailedProfileInfo | undefined;
  requiredFields: RequiredProfileFields;
}): boolean {
  if (!profile) return false;

  const requiredKeys = mapRequiredFieldsToKeys(requiredFields);

  const profileWithRequiredKeys = pick(profile, requiredKeys as (keyof DetailedProfileInfo)[]);

  return Object.values(profileWithRequiredKeys).some((val) => !val);
}

const requiredFieldMap = new Map<ExtraRequiredProfileField, (keyof FormValues)[]>([
  ['FirstName', ['firstName']],
  ['LastName', ['lastName']],
  ['CompanyName', ['companyName']],
  ['PhoneNumber', ['phoneNumber']],
  ['JobTitle', ['jobTitleId', 'jobTitleGroupName']],
  ['Location', ['locationId', 'locationName']],
  ['Password', ['password', 'hasExistingPassword']],
  ['Terms', ['termsAndConditionsAccepted']],
  ['Email', ['email']],
  ['Sso', ['isSso']],
]);

function mapRequiredFieldsToKeys(requiredFields: RequiredProfileFields) {
  let keys: (keyof FormValues)[] = [];

  requiredFields.forEach((field) => {
    if (requiredFieldMap.has(field)) {
      const key = requiredFieldMap.get(field);
      if (key?.length) {
        keys = keys.concat(key);
      }
    }
  });

  return keys;
}

function getRequiredFieldsByKeys(keys: Array<keyof FormValues>) {
  const requiredFields: ExtraRequiredProfileField[] = [];

  for (const key of keys) {
    if (key === 'jobTitleId') {
      requiredFields.push('JobTitle');
    } else if (key === 'locationId') {
      requiredFields.push('Location');
    } else {
      const requiredFieldKey = (key.charAt(0).toUpperCase() +
        key.slice(1)) as ExtraRequiredProfileField;
      requiredFields.push(requiredFieldKey);
    }
  }
  return requiredFields;
}

export const enrichProfileValues = (
  initialValues: FormValues,
  enrichValues: FormValues,
): FormValues => {
  const profileValues = {} as Record<keyof FormValues, any>;

  keys(enrichValues).forEach((k) => (profileValues[k] = initialValues[k] || enrichValues[k]));

  return { ...initialValues, ...profileValues };
};

export {
  fieldDataFromSsoProvider,
  fieldValueExists,
  getDefaultDropdownValues,
  getRequiredFieldsByKeys,
  isErrorWithFormValue,
  mapDetailedProfileInfo,
  mapRequiredFieldsToKeys,
  profileHasEmptyFields,
};

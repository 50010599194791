import {
  Box,
  Tooltip as MuiTooltip,
  SxProps,
  Typography,
  TypographyProps,
  styled,
} from '@mui/material';
import cls from 'classnames';
import { merge } from 'lodash';
import { ComponentProps, ReactNode, isValidElement } from 'react';

import { UtilityProps } from '../../utils/prop';

type BaseProps = {
  /** The content of the component */
  title: ReactNode;

  /** The variant of typography component used for `title` */
  typographyVariant?: TypographyProps['variant'];

  /** The anchor element that the component is mounted */
  children: ReactNode;

  /** If `true`, don't show tooltip at all */
  disabled?: boolean;

  /** The background that this tooltip is displayed on, dark will make tooltip's background lighter */
  forBackground?: 'Light' | 'Dark';

  /** The length of text in tooltip, long will reduce visual weight and increase readability */
  textType?: 'Short' | 'Long';
} & UtilityProps;

export type Props = BaseProps & Omit<ComponentProps<typeof MuiTooltip>, keyof BaseProps>;

const classes = {
  forLightBackground: 'Tooltip-for-light-background',
  forDarkBackground: 'Tooltip-for-dark-background',

  shortText: 'Tooltip-short-text',
  longText: 'Tooltip-long-text',
};

/** This component inherits [MUI Tooltip's API](https://mui.com/material-ui/api/tooltip/)\
 * See the [API documented on Storybook](https://ansarada-design-system.vercel.app/?path=/story/elements-messages--tooltip-story)
 */
const Tooltip = styled(
  ({
    className,
    forBackground = 'Light',
    textType = 'Short',
    title,
    children,
    ref,
    disabled,
    typographyVariant = 'body2',
    ...props
  }: Props) => {
    return (
      <MuiTooltip
        {...merge<Partial<Props>, Props>(
          {
            ref,
            classes: {
              tooltip: cls(
                className,
                forBackground === 'Dark' && classes.forDarkBackground,
                forBackground === 'Light' && classes.forLightBackground,
                textType === 'Long' && classes.longText,
                textType === 'Short' && classes.shortText,
              ),
            },
            placement: 'bottom-start',
            title: <Typography variant={typographyVariant}>{title}</Typography>,

            ...(disabled && {
              disableFocusListener: true,
              disableHoverListener: true,
              disableTouchListener: true,
            }),
          },
          props as Props,
        )}
      >
        {isValidElement(children) ? (
          children
        ) : (
          <Box
            component="span"
            sx={{ textDecorationLine: 'underline', textDecorationStyle: 'dashed' }}
          >
            {children}
          </Box>
        )}
      </MuiTooltip>
    );
  },
)(({ theme: { palette, spacing } }) => {
  return {
    lineHeight: '14px',

    padding: spacing(3),
    margin: '0px !important',
    marginTop: `${spacing(1)} !important`,

    borderRadius: '4px',

    [`&.${classes.shortText}.${classes.forLightBackground}`]: {
      backgroundColor: palette.$earth.$500,
      color: palette.$solar.$50,
    },

    [`&.${classes.longText}.${classes.forLightBackground}, &.${classes.longText}.${classes.forDarkBackground}`]:
      {
        backgroundColor: palette.$solar.$500,
        border: `1px solid ${palette.$solar.$700}`,
        color: palette.$earth.$500,
      },

    [`&.${classes.shortText}.${classes.forDarkBackground}`]: {
      backgroundColor: palette.$earth.$400,
      color: palette.$solar.$50,
    },
  } satisfies SxProps;
});

Tooltip.displayName = 'Tooltip';
export { Tooltip, classes as tooltipClasses };

import { SxProps, styled } from '@mui/material';
import { ComponentProps, ElementType } from 'react';

type Props<TComponent extends ElementType = ElementType> = {
  children: string;
  className?: string;

  component?: TComponent;
  componentProps?: Omit<
    ComponentProps<TComponent>,
    'children' | 'component' | 'componentProps' | 'className'
  >;
};

const ToastAction = styled(({ children, component, className, componentProps }: Props) => {
  const Component = component ?? 'button';

  return (
    <Component className={className} {...componentProps}>
      {children}
    </Component>
  );
})(
  () =>
    ({
      backgroundColor: 'unset',
      border: 'unset',
      cursor: 'pointer',

      fontWeight: 'bold',
      fontSize: '12px',
      textDecoration: 'underline',
      height: '16px',

      '&:hover': {
        textDecoration: 'none',
      },
    } satisfies SxProps),
);

ToastAction.displayName = 'ToastAction';

export { ToastAction };
export type { Props as ToastActionProps };

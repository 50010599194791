import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import type { DetailedProfileInfo } from '~/types';

function useUserSession(profileInfo?: DetailedProfileInfo) {
  const [isCheckingUserSession, setIsCheckingUserSession] = useState<boolean>(false);
  const {
    isLoading: auth0Loading,
    getAccessTokenSilently,
    isAuthenticated,
    user,
    loginWithRedirect,
  } = useAuth0();

  useEffect(() => {
    const checkSession = async () => {
      setIsCheckingUserSession(true);

      try {
        // getAccessTokenSilently will make isAuthenticated becomes true if there is an active auth0 session.
        // See detail implementation here: https://github.com/auth0/auth0-react/blob/24b9919a2ff27ba26ca163584378a59e49680e11/src/auth0-provider.tsx#L265
        await getAccessTokenSilently();
      } catch (error) {
        console.log('User not logged in.');
      }

      setIsCheckingUserSession(false);
    };

    if (!auth0Loading && !isAuthenticated) {
      checkSession();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0Loading, isAuthenticated]);

  const isNewAuthenticated = !isCheckingUserSession && isAuthenticated;
  const loggedWithDiffUser =
    isNewAuthenticated && profileInfo?.email?.toUpperCase() !== user?.email?.toUpperCase();

  return {
    user,
    loggedWithDiffUser,
    isCheckingUserSession: isCheckingUserSession || auth0Loading,
    isAuthenticated: (!isCheckingUserSession && isAuthenticated) || false,
    loginWithRedirect,
  };
}

export { useUserSession };

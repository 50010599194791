import { Children, ElementType, FC, ReactElement, ReactNode, isValidElement } from 'react';

export type SlotConfig<T extends string> = {
  [slotName in T]: ElementType | ElementType[];
};

export type SlotConfigProps<T extends string> = {
  /** Override the slots used in the component */
  slotConfig?: Partial<SlotConfig<T>>;
};

type UseSlotReturnType<T extends string> = Record<T, ReactElement[]> & {
  unslotted: ReactNode[];
  array: ReactNode[];
};

export const useSlot = <T extends string>(
  children: ReactNode,
  slotConfig: SlotConfig<T>,
  options?: {
    map?: Partial<Record<T, (e: ReactElement, index: number) => ReactElement>>;
    forEach?: Partial<Record<T, (e: ReactElement, index: number) => void>>;
    push?: Partial<
      Record<
        T,
        (
          e: ReactElement,
          index: number,
        ) => {
          before: ReactElement[];
          after: ReactElement[];
        }
      >
    >;
  },
): UseSlotReturnType<T> => {
  const { map, push, forEach } = options ?? {};

  const childrenArray = Children.toArray(children);
  const configs = Object.entries(slotConfig);

  const unslotted: ReactNode[] = [];
  const array: ReactNode[] = [];
  const slotted = configs.reduce((obj, [slotName]) => ({ ...obj, [slotName]: [] }), {}) as Record<
    T,
    ReactElement[]
  >;

  let index = -1;
  for (let child of childrenArray) {
    index++;
    let isThisChildSlotted = false;

    if (!isValidElement(child)) {
      unslotted.push(child);
      array.push(child);

      continue;
    }

    for (const [slotName, slotTypes] of configs) {
      for (const slotType of Array.isArray(slotTypes) ? slotTypes : [slotTypes]) {
        const childType = child.type as FC;
        const fcSlotType = slotType as FC;

        if (
          childType === slotType ||
          (childType?.displayName === fcSlotType.displayName &&
            childType?.displayName &&
            fcSlotType.displayName)
        ) {
          forEach?.[slotName as unknown as T]?.(child, index);
          child = map?.[slotName as unknown as T]?.(child, index) ?? child;

          const { after, before } = push?.[slotName as unknown as T]?.(child, index) ?? {
            after: [],
            before: [],
          };

          slotted[slotName as unknown as T].push(...before, child, ...after);
          array.push(...before, child, ...after);

          isThisChildSlotted = true;

          break;
        }
      }

      if (isThisChildSlotted) break;
    }

    if (isThisChildSlotted) continue;

    array.push(child);
    unslotted.push(child);
  }

  return { unslotted, array, ...slotted } as UseSlotReturnType<T>;
};

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Button } from '@ansarada/components/Button';

export type ProfileFormSectionProps = {
  title: string;
  description: string | React.ReactNode;
  isSubmitting?: boolean;
  submitButtonText?: string;
  isDisableSubmit?: boolean;
  submitErrorMsg?: string | string[];
  children: React.ReactNode;
};

function ProfileFormSection(props: ProfileFormSectionProps) {
  const {
    title,
    description,
    submitButtonText,
    submitErrorMsg,
    isSubmitting = false,
    isDisableSubmit = false,
  } = props;
  return (
    <Stack spacing={6}>
      <Stack spacing={3}>
        <Typography variant="h6" fontWeight="bold">
          {title}
        </Typography>
        <Stack data-test-id="description" spacing={1}>
          {description}
        </Stack>
      </Stack>
      {!!submitErrorMsg && <Alert severity="error">{submitErrorMsg}</Alert>}

      <Stack spacing={5}>
        {props.children}
        <Box style={{ marginLeft: 'auto' }}>
          <Button
            variant="primary"
            data-test-id="profile-form-submit-button"
            type="submit"
            disabled={isDisableSubmit || isSubmitting}
            fullWidth
            size="large"
            loading={isSubmitting}
          >
            {submitButtonText}
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
}

export { ProfileFormSection };

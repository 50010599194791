import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './app';
import config from './config';
import { setup } from './lib/segment';

const container = document.getElementById('root') as HTMLDivElement;
const root = createRoot(container);

setup(config.segmentApiKey);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);

import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import {
  EventParams,
  IdentifyParams,
} from '@segment/analytics-next/dist/types/core/arguments-resolver';

import { DefaultProperty } from './type';

interface SetupOptions {
  writeKey: string;
}

class Segment {
  #analytics?: Analytics;
  #defaultProps?: DefaultProperty;

  get analytics() {
    return this.#analytics;
  }

  get defaultProps() {
    return this.#defaultProps;
  }

  async setup({ writeKey }: SetupOptions) {
    if (!writeKey) {
      throw new Error('writeKey is required.');
    }
    const [analytics] = await AnalyticsBrowser.load({ writeKey });
    this.#analytics = analytics;
    return analytics;
  }

  identify(...args: IdentifyParams) {
    this.#analytics?.identify(...args);
  }

  setupDefaultProperties(props: DefaultProperty) {
    this.#defaultProps = props;
  }

  track(...args: EventParams) {
    const [eventName, props, ...restArgs] = args;
    this.#analytics?.track(
      eventName,
      {
        ...this.#defaultProps,
        ...props,
      },
      ...restArgs,
    );
  }
}

export default Segment;

import { SvgIcon, SvgIconProps } from '@mui/material';

export function Icon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <rect x="0.5px" y="0.5px" width="23px" height="23px" rx="4px" ry="4px" />

      <path
        style={{ transform: 'translate(7px, 5px)' }}
        d="M1 8.33317L5 12.3332L9 1.6665"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <line
        x1="6.5"
        y1="12"
        x2="17.5"
        y2="12"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

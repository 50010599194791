import auth0 from 'auth0-js';
import * as React from 'react';
import appConfig from '~/config';
import { getErrorMessage } from '~/utils/misc';

const DATABASE_CONNECTION_NAME = 'Username-Password-Authentication';

export function useLoginSilently() {
  const auth0Client = React.useMemo(
    () =>
      new auth0.WebAuth({
        responseType: 'code',
        domain: appConfig.auth.domain,
        clientID: appConfig.auth.clientId,
      }),
    [],
  );

  return React.useCallback(
    ({
      email,
      password,
      assetCallbackUrl,
    }: {
      email: string;
      password: string;
      assetCallbackUrl: string;
    }) => {
      auth0Client.login(
        {
          username: email,
          password,
          realm: DATABASE_CONNECTION_NAME,
          redirectUri: appConfig.auth.signupRedirectUri,
          audience: appConfig.auth.audience,
          scope: appConfig.auth.scope,
          state: `assetCallbackUrl=${encodeURIComponent(assetCallbackUrl)}`,
        },
        (err) => {
          const message = getErrorMessage(err);
          console.error(message);
        },
      );
    },
    [auth0Client],
  );
}

import { PopoverProps } from '@mui/material';
import { ReactElement } from 'react';

import { Divider } from '../Divider';

export const addDividers = (groups: ReactElement[], id: string) => {
  return groups.reduce<ReactElement[]>(
    (array, group, groupIndex) =>
      groupIndex === 0
        ? [...array, group]
        : [
            ...array,
            <Divider
              sx={({ palette }) => ({ mt: '10px', mb: '5px', borderColor: palette.$solar.$700 })}
              key={`divider-${groupIndex}-${id}`}
            />,
            group,
          ],
    [],
  );
};

export enum MenuPlacement {
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',

  TOP_LEFT = 'TOP_LEFT',
  TOP_RIGHT = 'TOP_RIGHT',
}

export enum MenuDirection {
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',

  TOP_LEFT = 'TOP_LEFT',
  TOP_RIGHT = 'TOP_RIGHT',
}

export const getOriginFromPlacementAndDirection = ({
  direction,
  placement,
}: {
  placement: `${MenuPlacement}`;
  direction: `${MenuDirection}`;
}) => {
  const originProps: Partial<Pick<PopoverProps, 'transformOrigin' | 'anchorOrigin'>> = {};
  if (placement === MenuPlacement.BOTTOM_LEFT) {
    originProps.anchorOrigin = { horizontal: 'left', vertical: 'bottom' };
  }

  if (placement === MenuPlacement.BOTTOM_RIGHT) {
    originProps.anchorOrigin = { horizontal: 'right', vertical: 'bottom' };
  }

  if (placement === MenuPlacement.TOP_LEFT) {
    originProps.anchorOrigin = { horizontal: 'left', vertical: 'top' };
  }

  if (placement === MenuPlacement.TOP_RIGHT) {
    originProps.anchorOrigin = { horizontal: 'right', vertical: 'top' };
  }

  if (direction === MenuDirection.BOTTOM_LEFT) {
    originProps.transformOrigin = { vertical: 'top', horizontal: 'right' };
  }

  if (direction === MenuDirection.BOTTOM_RIGHT) {
    originProps.transformOrigin = { vertical: 'top', horizontal: 'left' };
  }

  if (direction === MenuDirection.TOP_LEFT) {
    originProps.transformOrigin = { vertical: 'bottom', horizontal: 'right' };
  }

  if (direction === MenuDirection.TOP_RIGHT) {
    originProps.transformOrigin = { vertical: 'bottom', horizontal: 'left' };
  }

  return originProps;
};

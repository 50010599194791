import { Typography } from '@mui/material';

import * as colors from '@ansarada/colors/lib/common';

import { MenuItemGroup } from '../menu';
import { HeaderMenu } from './header-menu';

interface UserMenuProps {
  name: string;
  menu?: MenuItemGroup[] | null;
}

const UserMenu = ({ name, menu }: UserMenuProps) => {
  return (
    <HeaderMenu
      id="user-menu"
      menu={menu}
      label={
        <Typography
          variant="body1"
          sx={{ display: { xs: 'none', md: 'inherit', color: colors.earth500 } }}
        >
          {name}
        </Typography>
      }
    />
  );
};

export { UserMenu };

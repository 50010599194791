import { getErrorMessage } from '~/utils/misc';

import { BadRequestError, NotFoundError, UnknownError } from './api-error';

const apiURL = process.env.GATEWAY_BASE_URL;

export type ConfigType<P> = RequestInit & {
  data?: P;
  idToken?: string;
  token?: string;
};

async function client<T, P>(
  endpoint: string,
  { data, token, idToken, headers: customHeaders, ...customConfig }: ConfigType<P> = {},
) {
  const headers: Record<string, string> = idToken
    ? {
        'X-Ansarada-Identity-User-IdToken': idToken,
        'Ansarada-User-IdToken': idToken,
      }
    : {};

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const config = {
    method: data ? 'POST' : 'GET',
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      'Content-Type': data ? 'application/json' : undefined,
      ...headers,
      ...customHeaders,
    },
    ...customConfig,
  };

  return fetch(`${apiURL}/${endpoint}`, config as RequestInit)
    .then(async (response) => {
      const isJSON = response.headers.get('content-type')?.includes('application/json');
      const isText = response.headers.get('content-type')?.includes('text/plain');

      const data = isJSON
        ? await response.json()
        : isText
          ? JSON.parse(await response.text())
          : undefined;

      if (response.ok) {
        return data as T;
      } else {
        switch (response.status) {
          case 400:
            throw new BadRequestError(data);
          case 404:
            throw new NotFoundError(data);
          default:
            throw new UnknownError(data);
        }
      }
    })
    .catch((error: unknown) => {
      console.error(getErrorMessage(error));

      return Promise.reject(error);
    });
}

export { client };

import { CircularProgress, CircularProgressProps, SxProps, styled } from '@mui/material';
import cls from 'classnames';
import { merge } from 'lodash';

import { UtilityProps } from '../../utils/prop';

type BaseProps = object;

const classes = {
  root: 'Spinner--root',
};

type Props = Omit<CircularProgressProps, keyof BaseProps> & BaseProps & UtilityProps;

/** This component inherits [MUI CircularProgress's API](https://mui.com/material-ui/api/circular-progress/)\
 * See the [API documented on Storybook](https://ansarada-design-system.vercel.app/?path=/docs/elements-loaders-props--documentation#Spinner)
 */
export const Spinner = styled(({ className, ...props }: Props) => {
  return (
    <CircularProgress
      {...merge(
        {
          className: cls(className, classes.root),
          thickness: 4,
          size: 13,
        } satisfies Props,
        props,
      )}
    />
  );
})(
  ({ theme: { palette } }) =>
    ({
      [`&.${classes.root}`]: {
        color: palette.$earth.$500,
      },
    }) satisfies SxProps,
);

import { useEffect } from 'react';
import { waitForSetup } from '~/lib/segment';
import { identify } from '~/lib/segment.action';

export type UserIdentity = {
  profileId?: string;
  email?: string;
  onTrack?: () => void;
};

export function useSegmentIdentify(userIdentity: UserIdentity) {
  useEffect(() => {
    waitForSetup(() => {
      const profileId = userIdentity.profileId || '';
      const email = userIdentity.email || '';
      if (profileId) {
        identify(profileId, email);

        if (userIdentity.onTrack) {
          userIdentity.onTrack();
        }
      }
    });
  }, [userIdentity.profileId, userIdentity.email, userIdentity.onTrack, userIdentity]);
}

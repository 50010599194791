import LoadingButton from '@mui/lab/LoadingButton';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { withTheme } from '~/components/hoc/withTheme';
import { ResetMFALayout } from '~/components/layout/reset-mfa-layout';
import { EVENTS_NAMES } from '~/config/events';
import { useRequestResetMFA } from '~/hook/use-request-reset-mfa';
import { useSegmentIdentify } from '~/hook/use-segment-identify';
import { track } from '~/lib/segment.action';

import { useTimeout } from '@ansarada/hooks/useTimeout';

const RESET_DISABLE_RESEND_CONFIRMATION_IN_SECONDS = 30000;

export const ResetMFA = withTheme('ESGLight', () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const state = searchParams.get('state') ?? window.btoa('{}');
  const requestResetMFAMutate = useRequestResetMFA();
  const [isSent, setIsSent] = useState(false);
  const [disableResendConfirmation, setDisableResendConfirmation] = useState(false);

  useTimeout(
    () => {
      setDisableResendConfirmation(false);
    },
    disableResendConfirmation ? RESET_DISABLE_RESEND_CONFIRMATION_IN_SECONDS : null,
  );

  const resetConfig = JSON.parse(window.atob(state));

  useEffect(() => {
    if (!resetConfig.email) {
      navigate('/404');
    }
  }, [navigate, resetConfig.email]);

  useSegmentIdentify({
    profileId: resetConfig?.globalProfileId,
    email: resetConfig?.email,
    onTrack: useCallback(() => {
      track(EVENTS_NAMES.RESET_MFA_CLICKED, {
        global_profile_id: resetConfig?.globalProfileId,
      });
    }, [resetConfig?.globalProfileId]),
  });

  const handleRequestResetMFA = async () => {
    const email = resetConfig?.email;
    requestResetMFAMutate.mutate(email, {
      onSuccess: () => {
        setIsSent(true);
        track(EVENTS_NAMES.RESET_MFA_EMAIL, {
          global_profile_id: resetConfig?.globalProfileId,
        });
      },
    });
  };

  const handleResendRequestResetMFA = () => {
    const email = resetConfig?.email;
    requestResetMFAMutate.mutate(email, {
      onSuccess: () => {
        setDisableResendConfirmation(true);
        track(EVENTS_NAMES.RESET_MFA_EMAIL, {
          global_profile_id: resetConfig?.globalProfileId,
        });
      },
    });
  };

  const handleClose = () => {
    window.close();
  };

  return (
    <ResetMFALayout
      title={isSent ? 'Check your email' : "We've got you"}
      content={
        isSent
          ? "We've sent an email to confirm the reset of your  multi-factor authentication."
          : "We're going to reset your access with multi-factor authentication (MFA). You'll receive an email to confirm the reset."
      }
      renderAction={() =>
        isSent ? (
          <>
            <LoadingButton
              variant="contained"
              onClick={handleResendRequestResetMFA}
              disabled={requestResetMFAMutate.isLoading || disableResendConfirmation}
              loading={requestResetMFAMutate.isLoading}
              sx={{
                borderRadius: '4px',
              }}
            >
              Resend email
            </LoadingButton>
            <LoadingButton
              variant="link"
              disabled={requestResetMFAMutate.isLoading}
              onClick={handleClose}
            >
              Close
            </LoadingButton>
          </>
        ) : (
          <LoadingButton
            variant="contained"
            onClick={handleRequestResetMFA}
            disabled={requestResetMFAMutate.isLoading || isSent}
            loading={requestResetMFAMutate.isLoading}
            sx={{
              borderRadius: '4px',
            }}
          >
            Reset access
          </LoadingButton>
        )
      }
    />
  );
});
